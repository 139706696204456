import React, { useEffect } from "react";

function IndexPage(props) {
  useEffect(() => {
    console.log("test");
  }, []);

  return <></>;
}

export default IndexPage;
